<template>
  <app-modal :title="title" @submit="onSubmit">
    <app-form-input-text
      v-model="form.label"
      data-cy-label
      label="Label"
      required
    />

    <app-form-input-text
      v-if="!tag"
      v-model="form.slug"
      data-cy-slug
      label="Slug"
      pattern="^[a-z0-9]+(-[a-z0-9]+)*$"
      required
    />

    <div v-if="displayCommonFields" class="flex gap-4">
      <app-form-input-text
        v-model="form.color"
        data-cy-color
        label="Color"
        pattern="#[a-f0-9]{6}"
        required
      />

      <app-form-input-text v-model="form.icon" data-cy-icon label="Icon">
        <template #append-inner>
          <app-icon v-if="form.icon" :icon="form.icon" />
        </template>
      </app-form-input-text>
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  parent?: DatabaseTable<"tags">;
  tag?: DatabaseTable<"tags">;
}>();

const title = computed(() =>
  properties.tag ? `Edit "${properties.tag.slug}" tag` : "Create a new tag",
);

const form = reactive<Omit<DatabaseInsertRequest<"tags">, "path">>({
  label: properties.tag?.label ?? "",
  slug: properties.tag?.slug ?? "",
  color: properties.tag?.color ?? properties.parent?.color ?? "",
  icon: properties.tag?.icon ?? properties.parent?.icon ?? "",
});

const displayCommonFields = computed(
  () => !properties.parent || properties.tag?.depth === 0,
);

const onSubmit = () =>
  useModal("tagEdition").close({ confirmed: true, payload: form });
</script>
